import './App.css';
import addBtn from './assets/add-30.png';
import home from './assets/home.svg';
// import saved from './assets/bookmark.svg';
// import rocket from './assets/rocket.svg';
import sendBtn from "./assets/send.svg";
import userIcon from "./assets/user-icon.png";
import gvai from './assets/gvai.png'
import { sendMsgToOpenAI } from './openai'
import { useEffect, useRef, useState } from 'react';
import TextToSpeech from './components/TextToSpeech';


function App() {

  const msgEnd = useRef(null)
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [messages, setMessages] = useState([
    {
      text: "Hello! Welcome to Suresh Gyan Vihar University. I am GV AI, an AI assistant, and I am here to assist you with any queries you may have. How can I help you today?",
      isBot: true,
    }
  ])

  useEffect(() => {
    msgEnd.current.scrollIntoView();
  }, [messages])

  // const handleSend = async () => {
  //   const text = input;
  //   setInput('');
  //   setMessages([
  //     ...messages,
  //     { text, isBot: false }
  //   ])

  //   const res = await sendMsgToOpenAI(input)
  //   console.log(res);
  //   setMessages([
  //     ...messages,
  //     { text: input, isBot: false },
  //     { text: res, isBot: true },

  //   ])
  //   console.log(messages);
  // }


  const handleSend = async () => {
    const text = input;
    setInput('');
    setMessages([...messages, { text, isBot: false }]);

    setLoading(true);

    const res = await sendMsgToOpenAI(input);
    setMessages([...messages, { text: input, isBot: false }, { text: res, isBot: true }]);

    setLoading(false);
  };


  const handleQuery = async (e) => {
    const text = e.target.value;
    setMessages([
      ...messages,
      { text, isBot: false }
    ])

    setLoading(true);
    const res = await sendMsgToOpenAI(text)
    console.log(res);
    setMessages([
      ...messages,
      { text, isBot: false },
      { text: res, isBot: true },

    ])
    console.log(messages);

    setLoading(false);
  }


  const handleEnter = async (e) => {
    if (e.key === 'Enter') await handleSend()
  }
  return (
    <div className="App">
      <div className='sideBar'>
        <div className='upperSide'>
          <div className='upperSideTop'>
            <img src={gvai} alt="Logo" className='logo' />
            <span className='brand'>Gyan Vihar AI</span>
          </div>
          <button className='midBtn' onClick={() => { window.location.reload() }}>
            <img src={addBtn} alt="new chat" className='addBtn' />
            New Chat
          </button>
          <div className='upperSideBottom'>
            <button className='query' value={"Should I pursue PHD?"} onClick={handleQuery}>
              Should I pursue PHD?
            </button>
            <button className='query' value={"Where is SGVU situated?"} onClick={handleQuery}>
              Where is SGVU situated?
            </button>
            <button className='query' value={"Tell me about SGVU?"} onClick={handleQuery}>
              Tell me about SGVU?
            </button>
          </div>

        </div>
        <div className='lowerSide'>

          <div className='listItems'>
            <img src={home} alt="Home" className='listItemsImg' />
            <a href={"https://sso.gyanvihar.org/"} className='anchor'> Home</a>
          </div>
          {/* <div className='listItems'>
            <img src={saved} alt="Saved" className='listItemsImg' />
            Saved
          </div>
          <div className='listItems'>
            <img src={rocket} alt="Rocket" className='listItemsImg' />
            Upgrade to Pro
          </div> */}
        </div>
      </div>
      <div className='main'>
        <div className="chats">
          {messages.map((message, i) => {

            return <div key={i} className={message.isBot ? "chat bot" : "chat user"}>
              <img className="chatimg" src={message.isBot ? gvai : userIcon} alt="" />
              <p className="txt">
                {message.text}
                <br />
                <TextToSpeech text={message.text} />
              </p>
            </div>
          })}
          <div ref={msgEnd} />
        </div>
        <span className={`loading-message ${loading ? 'show' : 'hide'}`}>
          AI is Typing <span className="dots">...</span>
        </span>
        <div className="chatFooter">
          <div className="inp">
            <input className="inputPlaceholder" type="text" name="" id="" placeholder="Send a Message" onKeyDown={handleEnter} value={input} onChange={(e) => { setInput(e.target.value) }} />
            <button className="send" onClick={handleSend}>
              <img src={sendBtn} alt="Send" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
