import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    }
    setIsPlaying(true);

    synth.speak(utterance);

    setIsPaused(false);
    setIsPlaying(true);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  // const handleStop = () => {
  //   const synth = window.speechSynthesis;

  //   synth.cancel();

  //   setIsPaused(false);

  return (
    <>
      <button className={isPlaying ? "playBtn" : "textToSpeech"} onClick={handlePlay}>
        {isPaused ? "▐▐" : "▶"}
      </button>
      <button className="textToSpeech" onClick={handlePause}>
        ❚❚{" "}
      </button>
      {/* <button className="p-1 m-2 rounded-md text-black" onClick={handleStop}>◼</button> */}
    </>
  );
};

export default TextToSpeech;
