import OpenAI from 'openai';

const openai = new OpenAI({
    apiKey: "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",// This is also the default, can be omitted,
    dangerouslyAllowBrowser: true
});


export async function sendMsgToOpenAI(message) {
    try {
        const res = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [{ "role": "system", "content":  "You are a helpful assistant created by suresh gyan vihar university. You answer doubts of students related to Suresh Gyan Vihar University, Jaipur so please act and talk like you are an ai assistant and help students. Remember your name is GV AI which stands for Gyan Vihar AI",
        }, 
            { "role": "user", "content": message }],
            // max_tokens: 100,
        });
        return res.choices[0].message.content
    } catch (error) {

        // Non-API error
        return (<>
            Please Check Your Internet Connection
        </>)
    }

}


export default sendMsgToOpenAI;